import {request} from '@/utils/request'

// 商品列表
export function getGoodsList(data) {
  return request('GET','/shopweb/goods', data, true)
}

// 商品详情
export function getGoodsInfo(data) {
  return request('POST','/shopweb/goods/detail', data, true)
}

// 添加购物车
export function postCart(data) {
  return request('POST','/shop/cart', data, true)
}

// 商品列表菜单 - 菜单
export function getGoodsListMenu(id, level, categoryId, name) {
  return request('POST','/menu/' + id + '/' + level + '/' + categoryId + '/' + name, '', true)
}

// 商品列表菜单 - 分类
export function getGoodsCategoryList(id) {
  return request('GET','/category/categoryList/' + id, '', true)
}

// 店铺
export function getStoreInfo(id) {
  return request('GET','/shopweb/goods/getCorporateById/' + id, '', true)
}

// 是否收藏
export function getGoodsCollect(id) {
  return request('GET','/shop/goods/collect/' + id, '', true)
}

// 收藏
export function putGoodsCollect(id) {
  return request('PUT','/shop/user/goods/collect/edit/' + id, '', true)
}

// 收藏列表
export function getGoodsCollectList() {
  return request('GET','/shop/user/goods/collect/list', {}, true)
}
