<template>
    <div class="shop_main noMenu">
        <div class="shop_order_main">
            <userMenu type="8" />

            <div class="shopKeepList">
                <Menu :type="1" />

                <div class="legend">
                    <span class="title">说明：</span>
                    <span class="text">以下内容为您收藏的商品列表，您可以鼠标浮动至商品后点击“移除收藏”将不想再关注的商品移出收藏夹。</span>
                </div>

                <div class="shop_list_goods" v-loading="isRequestLoading">
                    <div class="content" v-if="listData.length > 0">
                        <a href="javascript:void(0)" class="item" v-for="(item, index) in listData" :key="item.id" @click.stop="$router.push({path: '/shop/detail', query: {id: item.goods_id}})">
                            <div class="image">
                                <el-image :src="imageURL + item.goods_image_url" fit="contain"></el-image>
                            </div>

                            <span class="price">¥ {{item.price}}</span>

                            <span class="name">{{item.goods_name}}</span>

                            <div class="info">
<!--                                <span class="text"><span>{{item.salesSum ? item.salesSum : 0}}</span> 笔成交</span>-->
                                <span class="text">{{item.store_name}}</span>
                            </div>

                            <a href="javascript:void(0)" class="remove" v-if="!item.isRemove" @click.stop="changeRemoveConfirm(item, index, true)">移出收藏夹</a>

                            <div class="confirm" v-if="item.isRemove" @click.stop="changeRemoveConfirm(item, index, true)">
                                <span class="text">确定移出收藏夹？</span>
                                <div class="btnGroup">
                                    <el-button class="sure" @click="putGoodsCollect(item.goods_id, item, index)">确定</el-button>
                                    <el-button class="cancel" @click.stop="changeRemoveConfirm(item, index, false)">取消</el-button>
                                </div>
                            </div>
                        </a>
                    </div>

                    <noDataView v-else/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {imageURL} from "../../../utils/config";
  import {getGoodsCollectList, putGoodsCollect} from "../../../api/shop/goods";
  const userMenu = () => import('../../../components/layout/shop/userMenu');
  const noDataView = () => import('../../../components/noDataView');
  const Menu = () => import('./menu');
  export default {
    name: "shopKeep",
    data () {
      return {
        listData: [],
        isRequestLoading: false,
        isSearchLoading: false,
        imageURL: imageURL,
        isQueryShow: false,
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getGoodsCollectList().then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.listData = res.data
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      changeRemoveConfirm (item, index, isRemove) {
        item.isRemove = isRemove
        this.$set(this.listData, index, item)
      },
      putGoodsCollect (id, item, index) {
        this.changeRemoveConfirm(item, index, false);
        putGoodsCollect(id).then(res => {
          if (res.code === 0) {
            this.getList()
          }
        })
      }
    },
    components: {
      userMenu, noDataView, Menu
    }
  }
</script>
